import {
  dateInterceptor,
  fingerprintInterceptor,
  impersonationInterceptor,
  wafInterceptor,
} from '@abb-procure/common';
import { ApplicationInsightsModule } from '@abb-procure/error-handling';
import { concurrentSessionInterceptor } from '@abb-procure/sessions';
import {
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  provideRouter,
  withComponentInputBinding,
  withDisabledInitialNavigation,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
  withPreloading,
  withRouterConfig,
} from '@angular/router';
import { MsalModule } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { NgSelectModule } from '@ng-select/ng-select';
import { AzureMapsModule } from 'ng-azure-maps';
import { quicklinkProviders, QuicklinkStrategy } from 'ngx-quicklink';
import { APP_PROVIDERS } from './app/app-module-providers';
import { AppComponent } from './app/app.component';
import { routes } from './app/app.routing';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      ApplicationInsightsModule.forRoot({
        instrumentationKey: environment.instrumentationKey,
        // @see https://stackoverflow.com/questions/58109846/how-to-programmatically-add-correlation-headers-to-application-insights-angular
        disableFetchTracking: false,
      }),
      MatSnackBarModule,
      MatDialogModule,
      MsalModule,
      NgSelectModule,
      MatNativeDateModule,
      AzureMapsModule.forRoot({
        authOptions: environment.mapOptions.authOptions,
      }),
    ),
    ...APP_PROVIDERS,
    provideAnimations(),
    provideHttpClient(
      withInterceptors([
        dateInterceptor,
        concurrentSessionInterceptor,
        impersonationInterceptor,
        fingerprintInterceptor,
        wafInterceptor,
      ]),
      withInterceptorsFromDi(),
    ),
    provideRouter(
      routes,
      withComponentInputBinding(),
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
      }),
      withRouterConfig({
        onSameUrlNavigation: 'reload',
      }),
      !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
        ? withEnabledBlockingInitialNavigation()
        : withDisabledInitialNavigation(),
      withPreloading(QuicklinkStrategy),
    ),
    quicklinkProviders,
  ],
}).catch((err) => {
  // eslint-disable-next-line no-console
  console.error(err);
});
