/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';

const removeAvatarUrl = (data: any): any => {
  if (Array.isArray(data)) {
    return data.map((item) => removeAvatarUrl(item));
  }

  if (data instanceof Date) {
    return data;
  }

  if (typeof data === 'object' && data !== null) {
    return Object.keys(data).reduce((acc, key) => {
      if (key !== 'avatarUrl') {
        acc[key] = removeAvatarUrl(data[key]);
      }

      return acc;
    }, {} as any);
  }

  return data;
};

export const wafInterceptor: HttpInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  if (!request.body) {
    return next(request);
  }

  return next(request.clone({ body: removeAvatarUrl(request.body) }));
};
